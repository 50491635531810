/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Bonito el nuevo tema del blog ¿no? Parece un cambio sin importancia pero tiene un sentido, ahorro energético. En los monitores, un punto negro gasta mucho menos que un punto blanco, con esta idea en mente nació ", React.createElement(_components.a, {
    href: "http://es.blackle.com/",
    title: "Blackle"
  }, "Blackle"), ", que es simplemente Google pero en negro, es una forma de ahorro como otra cualquiera, la costumbre siempre a sido que las paginas estubieran en blanco y escribir en negro, como en un folio, pero ya va siendo hora de que nos vayamos concienciando y utilicemos la pantalla del ordenador como lo que es, una pizarra. En un monitor los puntos de color se escriben sobre el fondo negro, igual que en una pizarra, y sobretodo en los CRT, que ya casi no se usan eso la diferencia de gasto es enorme, por eso desde aquí animo a todo el mundo a que tenga en su PC fondos oscuros, que las paginas web los tengan y en todos los lugares donde se pueda tener. La tecnología también puede ser ecológica."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
